import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { ImplementationsView } from 'utilities/components/implementations/component/view';
import { brands } from 'utilities/data/doors';

export const Implementations = () => {
    const data = useStaticQuery(graphql`
        query allRealizationsSlidersPremium {
            allRealizationsSliders {
                nodes {
                  ordered_slider_data {
                    premium {
                      addon
                      alternative_id
                      color
                      frame
                      glass
                      images
                      link_product
                      main_image
                      alt_main_image
                      name
                      date
                      trims {
                        image
                        name
                      }
                      lines {
                        brands {
                          name
                          type
                        }
                        name
                      }
                      accessory
                    }
                  }
                }
              }
        }
    `);

    return <ImplementationsView brand={ brands.smart } realizations={ (data?.allRealizationsSliders?.nodes?.length && data?.allRealizationsSliders?.nodes[0]?.ordered_slider_data?.premium) ?? [] } />;
};
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Carousel } from 'features/index/carousel/component';
import { DoorView } from 'utilities/components/door/component/view';

import './view.scss';

export const ModelsView = ({ models, types }) => {
    const [type, setType] = useState('');
    const filterItems = () => (models.filter((currentValue) => (currentValue.type === type)));

    return (
        <Carousel
            headline="Modele Barański Premium"
            buttonText="Zobacz więcej"
            // /baranski-premium is modifier for newly generated link for the button below carousel. It is used in <Carousel> from /features/index/carousel
            buttonLink="/baranski-premium"
            modifier="models__item"
            types={ types }
            slidesPerView="auto"
            loopBreakpoint={ 7 }
            type={ type }
            items={ (type)
                ? (filterItems())
                : (models) }
            setType={ setType }
        >
            <DoorView inSwiper />
        </Carousel>
    );
};

ModelsView.propTypes = {
    models: PropTypes.instanceOf(Array).isRequired,
    types: PropTypes.instanceOf(Array)
};

ModelsView.defaultProps = {
    types: undefined
};
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';

import { Navigation } from 'utilities/components/navigation/component';
import { Footer } from 'utilities/components/footer/component';
import { CardView } from '../features/products/card/component/view';
import { BannerView } from 'utilities/components/banner/component/view';
import { DescriptionView } from 'features/premium/description/component/view';
import { PatternOuter } from 'features/premium/pattern-outer/component/index';
import { PatternInner } from 'features/premium/pattern-inner/component/index';
import { Models } from 'features/premium/models/component/index';
import { Advantages } from 'features/premium/advantages/component';
import { Implementations } from 'features/premium/implementations/component';
import { Articles } from 'features/premium/articles/component';
import { Catalogues } from 'features/premium/catalogues/component';
import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { paths, routes } from 'utilities/routes';
import MessengerCustomerChat from 'react-messenger-customer-chat';

import banner2 from 'assets/images/index/banner--2.jpg';
import setSquare from 'assets/images/navigation/set-square.svg';

import LogRocket from 'logrocket';
import SEO from 'utilities/components/seo';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';

LogRocket.init('qijaj0/baranski');

const Premium = () => {
    const breadcrumbs = [routes.home, routes.premium];
    const data = useStaticQuery(graphql`
        query GetBannerPremium {
            banners(site_type: { eq: "Marka Premium" }) {
                image
                alt_image
                background_position_x
            }
        }
    `);

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };
    const { metaTitle, metaDescription, description } = useSeoTexts('Premium');
    return (
        <main className="premium">
            <SEO
                title={ metaTitle || 'Barański Drzwi - Nasze marki - Barański Drzwi Premium' }
                description={
                    metaDescription
                    || 'Drzwi wewnętrzne, drzwi zewnętrzne, drzwi drewniane. Drzwi Barański Premium są w pełni dostosowane do oczekiwań klientów. Poznaj naszą linie.'
                }
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                alt={ data?.banners?.alt_image }
                canonicalLink={ setCanonicalLink() }
            />
            <Navigation />
            <BannerView
                image={ `${process.env.GATSBY_URL}/${data?.banners?.image}` }
                headline="Jakość i elegancja drewna w Twoim domu"
                subtitle="Drzwi zewnętrzne i wewnętrzne"
                type="premium"
                x={ data?.banners?.background_position_x }
            />
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <DescriptionView />
            <Advantages />
            <PatternOuter />
            <div className="products">
                <CardView
                    image={ banner2 }
                    icon={ setSquare }
                    headline="Realizujemy najbardziej niestandardowe projekty drzwi zewnętrznych"
                    description="Wystarczy rysunek na kartce papieru, byśmy urzeczywistnili wizję klienta."
                    buttonText="Wyślij swój projekt"
                    buttonLink={ paths.drzwiNaWymiar }
                    shortContainer
                />
            </div>
            <PatternInner />
            <Models />
            <Catalogues />
            <Articles />
            <Implementations />
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Premium;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CarouselView } from './view';

export const Carousel = ({
    headline,
    buttonText,
    buttonLink,
    name,
    modifier,
    items,
    subTypes,
    children,
    loopBreakpoint,
    ...settings
}) => {
    const [subType, setSubType] = useState(subTypes?.[0] || '');

    const filterItems = () => (items.filter((currentValue) => (currentValue.subtype === subType)));

    return (
        <CarouselView
            headline={ headline }
            buttonText={ buttonText }
            buttonLink={ buttonLink }
            name={ name }
            modifier={ modifier }
            items={
                (subType)
                    ? (filterItems())
                    : (items)
            }
            subTypes={ subTypes }
            subType={ subType }
            loopBreakpoint={ loopBreakpoint }
            loop
            setSubType={ setSubType }
            { ...settings }
        >
            { children }
        </CarouselView>
    );
};

Carousel.propTypes = {
    headline: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    name: PropTypes.string.isRequired,
    modifier: PropTypes.string.isRequired,
    items: PropTypes.instanceOf(Array).isRequired,
    subTypes: PropTypes.instanceOf(Array),
    children: PropTypes.node.isRequired,
    loopBreakpoint: PropTypes.number.isRequired
};

Carousel.defaultProps = {
    buttonText: undefined,
    buttonLink: undefined,
    subTypes: undefined
};
import React from 'react';

import { PatternOuterView } from './view';

import doorPremiumOuterNowoczesne from 'assets/images/premium/pattern-outer/premium_nowoczesne_zew.png';
import doorPremiumOuterTradycyjne from 'assets/images/premium/pattern-outer/premium_tradycyjne_zew.png';
import doorPremiumOuterWyjatkowe from 'assets/images/premium/pattern-outer/premium_wyjatkowe_zew.png';

export const PatternOuter = () => {
    const data = {
        image: doorPremiumOuterNowoczesne,
        links: [
            {
                title: 'Nowoczesne',
                image: doorPremiumOuterNowoczesne
            },
            {
                title: 'Tradycyjne',
                image: doorPremiumOuterTradycyjne
            },
            {
                title: 'Wyjątkowe',
                image: doorPremiumOuterWyjatkowe
            }
        ],
        title: 'Drzwi zewnętrzne',
        subtitle: 'Drzwi zewnętrzne Barański Premium to estetyka i bezpieczeństwo w jednym. Dzięki znakomitemu designowi i wysokiej trwałości będą wizytówką Twojego domu przez wiele lat.',
        description: 'Wybierz model, który spełnia Twoje wymagania lub zaprojektuj go sam! Drzwi Barański Premium dostępne są w szerokiej gamie stylów. Wymagającym klientom zapewniamy również możliwość personalizacji i stworzenia drzwi od podstaw zgodnie z wytycznymi.'
    };

    return (<PatternOuterView data={ data } color="#a89970" />);
};
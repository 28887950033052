import React from 'react';

import { Img } from 'utilities/components/img/component';

import baranskiPremium from 'assets/images/baranski-premium.svg';

import 'utilities/components/description/component/view.scss';

// TODO - use universal component from 'src/utilities/components/description/component/view.jsx' if possible (bold words)
export const DescriptionView = () => (
    <section className="description">
        <Img src={ baranskiPremium } alt="Barański Premium" />
        <p className="description__text">
            Barański Premium to drzwi dla wymagających klientów, którzy poszukują wyjątkowej jakości oraz piękna natury, jakie przejawia się w prawdziwym drewnie. Zarówno drzwi wewnętrzne, jak i drzwi zewnętrzne wyróżniają pierwszorzędne materiały i komponenty. Drewniana konstrukcja, naturalne okleiny, trzykrotne lakierowanie oraz wykorzystanie ozdobnego szkła, kamienia czy metalu nadają drzwiom szczególną trwałość i wyjątkowy charakter. Modele Premium odznaczają się także starannością wykonania. Każde drzwi są
            <span className="description__text--bold"> wykańczane ręcznie</span>
            . Nad każdym egzemplarzem czuwają mistrzowie produkcji, dbający o najmniejszy detal.
        </p>
    </section>
);
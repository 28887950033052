import React from 'react';
import classnames from 'classnames';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';

import { HeadlineView } from 'utilities/components/headline/component/view';
import { ButtonsView } from 'utilities/components/buttons/component/view';
import { ButtonView } from 'utilities/components/button/component/view';

import './view.scss';

SwiperCore.use([Navigation]);

export const CarouselView = ({
    headline,
    buttonText,
    buttonLink,
    name,
    modifier,
    items,
    subTypes,
    subType,
    children,
    loopBreakpoint,
    setSubType,
    ...settings
}) => (
    <section className="carousel">
        <HeadlineView text={ headline } />
        {
            subTypes
            && (
                <Swiper
                    className="carousel__types"
                    slidesPerView="auto"
                    centerInsufficientSlides
                    onSwiper={ (swiper) => {
                        setTimeout(() => {
                            swiper.update();
                        }, 1000);
                    } }
                >
                    {
                        subTypes.map((currentValue, index) => (
                            <SwiperSlide className="types__type" key={ index } style={{ width: 'auto' }}>
                                <button
                                    className={
                                        classnames('type__button', {
                                            'type__button--active': (subType === currentValue)
                                        })
                                    }
                                    type="button"
                                    onClick={ () => {
                                        setSubType(
                                            (subType === currentValue)
                                                ? ('')
                                                : (currentValue)
                                        );
                                    } }
                                >
                                    { currentValue }
                                </button>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            )
        }
        <Swiper
            { ...settings }
            className="carousel__container"
            navigation={{
                nextEl: '.buttons__button--next',
                prevEl: '.buttons__button--previous'
            }}
            loop={ items.length > loopBreakpoint }
            loopedSlides={ items.length }
            centerInsufficientSlides
        >
            {
                items?.map((currentValue, index) => (
                    <SwiperSlide className={ modifier } key={ index }>
                        { React.cloneElement(children, { ...currentValue }) }
                    </SwiperSlide>
                ))
            }
            <ButtonsView name={ name } />
        </Swiper>
        {
            buttonText && <ButtonView text={ buttonText } link={ buttonLink } />
        }
    </section>
);

CarouselView.propTypes = {
    headline: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
    name: PropTypes.string.isRequired,
    modifier: PropTypes.string.isRequired,
    items: PropTypes.instanceOf(Array).isRequired,
    subTypes: PropTypes.instanceOf(Array),
    subType: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    loopBreakpoint: PropTypes.number.isRequired,
    setSubType: PropTypes.func.isRequired
};

CarouselView.defaultProps = {
    buttonText: undefined,
    buttonLink: undefined,
    subTypes: undefined
};
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';

import { paths } from 'utilities/routes';
import { doorTypes } from 'utilities/data/doors';
import { ArticlesViewPremium } from './view';

export const Articles = () => {
    const data = useStaticQuery(graphql`
        query {
            allArticles(filter: {
                is_active: {
                    eq: 1
                }
                type: {
                    eq: "Technologie"
                }
            }) {
                nodes {
                    alternative_id
                    author
                    created_at(formatString: "DD.MM.YYYY")
                    image_main
                    lead
                    subtype
                    slug
                    title
                    type
                    reading_time
                    slug
                }
            }
        }
    `);

    const newArticlesPremium = _.uniqBy(data?.allArticles?.nodes?.filter((article) => article?.subtype?.toLowerCase()?.includes('premium')), 'alternative_id');

    const newArticles = newArticlesPremium?.map((article) => {
        let newArticle = { ...article };
        if (newArticle?.subtype?.includes('zewnętrzne')) {
            newArticle = { ...newArticle, subtype: doorTypes.outer.shortName };
        } else if (newArticle?.subtype?.includes('wewnętrzne')) {
            newArticle = { ...newArticle, subtype: doorTypes.inner.shortName };
        }

        return newArticle;
    });

    const subTypes = [...new Set(newArticles.map((article) => article?.subtype) ?? [])];

    return (<ArticlesViewPremium articles={ newArticles } subTypes={ subTypes } title="Technologie" buttonText="Wszystkie artykuły" buttonLink={ paths.wszystkoODrzwiach } />);
};
import React from 'react';

import advantage1 from 'assets/images/premium/advantages/1.png';
import advantage3 from 'assets/images/premium/advantages/3.png';
import advantage5 from 'assets/images/premium/advantages/5.png';

import { AdvantagesView } from './view';

export const Advantages = () => {
    const data = [
        {
            title1: 'Drewno to podstawa ',
            title2: '',
            description: 'Solidność drewna, wytrzymałość konstrukcji i piękno naturalnych drewnianych fornirów.',
            image: advantage1
        },
        {
            title1: '3-krotne lakierowanie',
            title2: '',
            description: 'Wyjątkowa intensywność kolorów i zabezpieczenie powierzchni drzwi.',
            image: advantage3
        },
        {
            title1: 'Ręczne wykończenie',
            title2: '',
            description: 'Dopracowane detale, dbałość o każdy szczegół.',
            image: advantage5
        }
    ];

    return (<AdvantagesView data={ data } color="#A79A70" />);
};
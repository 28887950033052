import React from 'react';
import PropTypes from 'prop-types';

import { Carousel } from 'features/premium/carousel/component';
import { ArticleView } from 'utilities/components/article/component/view';

import './view.scss';

export const ArticlesViewPremium = ({
    articles, subTypes, title, buttonText, buttonLink
}) => (
    <Carousel
        headline={ title }
        buttonText={ buttonText }
        buttonLink={ buttonLink }
        name="articles"
        modifier="articles__item"
        items={ articles }
        subTypes={ subTypes }
        loopBreakpoint={ 3 }
        slidesPerView="1"
        breakpoints={{
            600: {
                slidesPerView: 'auto'
            }
        }}
    >
        <ArticleView />
    </Carousel>
);

ArticlesViewPremium.propTypes = {
    articles: PropTypes.instanceOf(Array).isRequired,
    subTypes: PropTypes.instanceOf(Array).isRequired,
    title: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string
};

ArticlesViewPremium.defaultProps = {
    buttonText: undefined,
    buttonLink: undefined
};
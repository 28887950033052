import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { ButtonView } from 'utilities/components/button/component/view';
import { Img } from 'utilities/components/img/component';

import './view.scss';

export const CardView = ({
    image,
    icon,
    headline,
    description,
    buttonText,
    buttonLink,
    additionalButtonText,
    additionalButtonLink,
    shortContainer
}) => (
    <div className={ classnames('card', { 'card--short': shortContainer }) }>
        <Img
            className={
                classnames('card__image', {
                    'card__image--hidden': icon
                })
            }
            src={ image }
            alt={ headline }
        />
        <div className="card__container">
            <h4 className="card__headline">
                {
                    (icon)
                        && (<Img className="card__icon" src={ icon } alt="ikona" />)
                }
                { headline }
            </h4>
            {
                (description)
                    && (<p className="card__description">{ description }</p>)
            }
            <div className="card__buttons">
                <ButtonView text={ buttonText } link={ buttonLink } />
                {additionalButtonLink && additionalButtonText
                    ? <ButtonView text={ additionalButtonText } link={ additionalButtonLink } />
                    : null}
            </div>

        </div>
    </div>
);

CardView.propTypes = {
    image: PropTypes.string.isRequired,
    icon: PropTypes.string,
    headline: PropTypes.string.isRequired,
    description: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    buttonLink: PropTypes.string.isRequired,
    additionalButtonText: PropTypes.string,
    additionalButtonLink: PropTypes.string,
    shortContainer: PropTypes.bool
};

CardView.defaultProps = {
    icon: '',
    description: '',
    additionalButtonText: '',
    additionalButtonLink: '',
    shortContainer: false
};
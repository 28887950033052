import React from 'react';
import { PatternInnerView } from './view';

import doorPremiumInner from 'assets/images/premium/drzwi-premium-wew.png';
import doorPremiumInnerTradycyjne from 'assets/images/premium/pattern-inner/Premium_tradycyjne_wew.png';
import doorPremiumInnerWyjatkowe from 'assets/images/premium/pattern-inner/Premium_wyjatkowe_wew.png';

export const PatternInner = () => {
    const data = {
        image: doorPremiumInner,
        links: [
            {
                title: 'Nowoczesne',
                image: doorPremiumInner
            },
            {
                title: 'Tradycyjne',
                image: doorPremiumInnerTradycyjne
            },
            {
                title: 'Wyjątkowe',
                image: doorPremiumInnerWyjatkowe
            }
        ],
        title: 'Drzwi wewnętrzne',
        subtitle: 'Drzwi wewnętrzne Barański Premium to wyjątkowe drzwi dla niebanalnych wnętrz. Drewniana konstrukcja, naturalne okleiny i 3-krotne lakierowanie nadają im niezwykłą trwałość oraz niepowtarzalny charakter.',
        description: 'Wybierz model, który spełnia Twoje wymagania lub stwórz go sam! Drzwi Barański Premium dostępne są w szerokiej gamie stylów. Wymagającym klientom zapewniamy również możliwość personalizacji i stworzenia projektu od podstaw zgodnie z wytycznymi.'
    };

    return (<PatternInnerView data={ data } color="#a89970" isReverse />);
};
import React from 'react';

import { ModelsView } from './view';
import { graphql, useStaticQuery } from 'gatsby';
import { parseDoors, doorTypes } from 'utilities/data/doors';

export const Models = () => {
    const data = useStaticQuery(graphql`
        query getBestsellersDoorsPremium {
            allDoors(
                filter: {
                    is_active: { eq: 1 }
                    bestseller: { eq: 1 }
                    lines: { brands: { name: { eq: "Barański Premium" } } }
                    id: { ne: "dummy" }
                }
            ) {
                nodes {
                    alternative_id
                    variants {
                        main
                        main_model
                    }
                    custom_variants {
                        main
                        main_model
                    }
                    name
                    lines {
                        name
                        brands {
                            cms_name
                            name
                            type
                        }
                    }
                    price
                    price_type
                    purposes {
                        name
                    }
                    style
                    bestseller
                }
            }
        }
    `);

    const newModels = data?.allDoors?.nodes?.map((model) => {
        let newModel = { ...model };
        if (newModel?.lines?.brands?.type?.includes('zewnętrzne')) {
            newModel = {
                ...newModel,
                lines: { ...newModel?.lines, brands: { ...newModel?.lines?.brands, type: doorTypes.outer.shortName } }
            };
        } else if (newModel?.lines?.brands?.type?.includes('wewnętrzne')) {
            newModel = {
                ...newModel,
                lines: { ...newModel?.lines, brands: { ...newModel?.lines?.brands, type: doorTypes.inner.shortName } }
            };
        }

        return newModel;
    });

    const types = [...new Set(newModels?.map((model) => model?.lines?.brands?.type) ?? [])];

    return <ModelsView models={ parseDoors(newModels) } types={ types } />;
};

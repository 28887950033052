import React from 'react';
import { ButtonView } from 'utilities/components/button/component/view';
import { Img } from 'utilities/components/img/component';
import PropTypes from 'prop-types';

import './view.scss';

export const CataloguesView = ({ dataIn, dataOut }) => (
    <section className="offer--background">
        <div className="offer__wrapper">
            <div className="offer">
                <div className="offer__container">
                    <Img className="offer__image" src={ `${process.env.GATSBY_URL}/${dataIn?.folderpage_thumbnail_img}` } alt="katalog" />
                    <h4 className="offer__headline">Drzwi wewnętrzne Barański Premium</h4>
                    <p className="offer__description"> </p>
                    <ButtonView text="Zobacz katalog" link={ `${process.env.GATSBY_URL}/${dataIn?.folder_file}` } download target="_blank" />
                </div>
            </div>
            <div className="offer">
                <div className="offer__container">
                    <Img className="offer__image" src={ `${process.env.GATSBY_URL}/${dataOut?.folderpage_thumbnail_img}` } alt="katalog" />
                    <h4 className="offer__headline">Drzwi zewnętrzne Barański Premium</h4>
                    <p className="offer__description"> </p>
                    <ButtonView text="Zobacz katalog" link={ `${process.env.GATSBY_URL}/${dataOut?.folder_file}` } download target="_blank" />
                </div>
            </div>
        </div>
    </section>
);

CataloguesView.propTypes = {
    dataIn: PropTypes.instanceOf(Object).isRequired,
    dataOut: PropTypes.instanceOf(Object).isRequired
};
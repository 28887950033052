import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { CataloguesView } from './view';

export const Catalogues = () => {
    const data = useStaticQuery(graphql`
        query GetPremiumData {
            allFolders(filter: {
                id: {
                    ne: "dummy"
                }
                assortment: {
                    name: {
                        in: ["Premium zewnętrzne", "Premium wewnętrzne"]
                    }
                }
            }) {
                nodes {
                    assortment {
                        name
                    }
                    folder_file
                    folderpage_thumbnail_img
                }
            }
        }
    `);

    const dataIn = data?.allFolders?.nodes.filter((currentValue) => (currentValue?.assortment?.name === 'Premium wewnętrzne'))[0];
    const dataOut = data?.allFolders?.nodes.filter((currentValue) => (currentValue?.assortment?.name === 'Premium zewnętrzne'))[0];

    return (<CataloguesView dataIn={ dataIn } dataOut={ dataOut } />);
};